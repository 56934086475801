import SubMenuItem, { SubMenuItemIcons } from '../Common/SubMenuItem';

import './InboxSubMenu.scss';
import { useAppSelector } from '../../store/hooks';

const InboxSubMenu = () => {
    const counts = useAppSelector((x) => x.inboxCounts);

    return (
        <div className='inbox__menu'>
            <SubMenuItem
                to='/inbox/unified/chat'
                area='/inbox/unified'
                icon={SubMenuItemIcons.unified}
                title='Unified Inbox'
                hint='All your messages unified'
                count={counts.emailsCounts + counts.textCounts}
            />

            <SubMenuItem
                to='/inbox/text/chat'
                area='/inbox/text'
                icon={SubMenuItemIcons.text}
                title='Texts'
                hint='SMS and MMS messages'
                count={counts.textCounts}
            />

            <SubMenuItem
                to='/inbox/email/chat'
                area='/inbox/email'
                icon={SubMenuItemIcons.email}
                title='Emails'
                hint='Email correspondence'
                count={counts.emailsCounts}
            />

            <SubMenuItem
                to='/inbox/trash/chat'
                area='/inbox/trash'
                icon={SubMenuItemIcons.trash}
                title='Deleted'
                hint='Removed messages'
                count={counts.trashedCount}
            />
        </div>
    );
};

export default InboxSubMenu;
