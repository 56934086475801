import { useNavigate } from 'react-router-dom';
import { Logout } from '../../Services/Auth';

import './Navigation.scss';
import NavigationLogo from './NavigationLogo';
import NavigationLink, { NavigationLinkIcons } from './NavigationLink';
import { NotifySuccess } from '../../Services/Notify';

export type NavigationProps = {
    expanded?: boolean;
};

const TelemarketerNavigation = (props: NavigationProps) => {
    const navigate = useNavigate();

    const logoutHandler: React.MouseEventHandler<HTMLAnchorElement> = async (
        e
    ) => {
        e.preventDefault();
        await Logout();
        NotifySuccess('Logout successfully');
        navigate('/login');
    };

    return (
        <nav
            className={`navigation ${props.expanded && 'navigation--expanded'}`}
        >
            <NavigationLogo expanded={props.expanded} />

            <div className='navigation__list'>
                <ul className='navigation__links'>
                    <li>
                        <NavigationLink
                            to='/telemarketer/leads'
                            area='/telemarketer/leads'
                            title='Leads'
                            icon={NavigationLinkIcons.leads}
                            withText={props.expanded}
                        />
                    </li>
                    <li>
                        <NavigationLink
                            to='/telemarketer/new-leads'
                            area='/telemarketer/new-leads'
                            title='New Leads'
                            icon={NavigationLinkIcons.newLead}
                            withText={props.expanded}
                        />
                    </li>
                    <li>
                        <NavigationLink
                            to='/telemarketer/callback-leads'
                            area='/telemarketer/callback-leads'
                            title='Callback Leads'
                            icon={NavigationLinkIcons.leadCheck}
                            withText={props.expanded}
                        />
                    </li>
                    <li>
                        <NavigationLink
                            to='#'
                            onClick={logoutHandler}
                            title='Logout'
                            icon={NavigationLinkIcons.logout}
                            withText={props.expanded}
                        />
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default TelemarketerNavigation;
