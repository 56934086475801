import { Link } from 'react-router-dom';
import './TermsAndPolicy.scss';
import { CSSProperties } from 'react';

type TermsAndPolicyProps = {
    byText?: string;
    style?: CSSProperties;
};

const TermsAndPolicy = (props: TermsAndPolicyProps) => {
    const displayedText = props?.byText || 'using our Application';

    return (
        <div className='termsAndPolicy' style={props?.style}>
            {' '}
            By {displayedText}, you accept our{' '}
            <Link to={'/terms-of-use'}>Terms of Use</Link> &{' '}
            <Link to={'/privacy-policy'} className='register__underline'>
                Privacy policy
            </Link>
        </div>
    );
};

export default TermsAndPolicy;
