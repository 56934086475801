import logo from '../../assets/img/leadqualifier-icon.svg';
import logoFull from '../../assets/img/leadqualifier-logo.svg';

import './NavigationLogo.scss';
import { Link } from 'react-router-dom';

const NavigationLogo = (props: { expanded?: boolean }) => {
    return (
        <Link
            className={`navigationLogo ${
                props.expanded && 'navigationLogo--expanded'
            }`}
            to='/'
        >
            <div className='navigationLogo__icon'>
                <img
                    className='navigationLogo__link'
                    src={logo}
                    alt='Lead Qualifier'
                />
            </div>

            <div className='navigationLogo__title'>
                <img src={logoFull} alt='Lead Qualifier' />
            </div>
        </Link>
    );
};

export default NavigationLogo;
