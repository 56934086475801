import { ReactNode, useEffect, useState } from 'react';
import { IconButton } from '../../Views/Common/Button';
import Backdrop from '../../Views/Common/Backdrop';

import './SubNavigationPanelMobile.scss';
import { useLocation } from 'react-router-dom';

type SubNavigationPanelMobileProps = {
    header?: ReactNode;
    navigation?: ReactNode;
};

const SubNavigationPanelMobile = (props: SubNavigationPanelMobileProps) => {
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();

    const toggle = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        setExpanded(false);
    }, [location.pathname]);

    return (
        <div className='subNavigationPanelMobile'>
            <div className='subNavigationPanelMobile__headWrapper'>
                <div className='subNavigationPanelMobile__head'>
                    {props.header}
                </div>
                <div className='subNavigationPanelMobile__toggleButton'>
                    <IconButton icon='la la-bars' click={toggle} />
                </div>
            </div>
            {expanded && (
                <div className='subNavigationPanelMobile__navigation'>
                    {props.navigation}
                </div>
            )}

            <Backdrop visible={expanded} onClick={toggle} />
        </div>
    );
};
export default SubNavigationPanelMobile;
