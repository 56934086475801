import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountsType } from '../../../Services/type';

const initialState: CountsType = {
    totalLeads: 0,
    totalFunnels: 0,
    inboxTotal: 0,
    tasksTotal: 0,
};

const countsSlice = createSlice({
    name: 'counts',
    initialState,
    reducers: {
        updateAllCounts: (state, action: PayloadAction<CountsType>) => {
            return action.payload;
        },
        updateLeadCount: (state, action: PayloadAction<number>) => {
            state.totalLeads = action.payload;
        },
        updateFunnelCount: (state, action: PayloadAction<number>) => {
            state.totalFunnels = action.payload;
        },
        updateInboxCount: (state, action: PayloadAction<number>) => {
            state.inboxTotal = action.payload;
        },
    },
});

export const {
    updateAllCounts,
    updateLeadCount,
    updateFunnelCount,
    updateInboxCount,
} = countsSlice.actions;
export default countsSlice.reducer;
