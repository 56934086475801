import { Link, useLocation } from 'react-router-dom';

import './NavigationLink.scss';
import { ReactNode } from 'react';
import { ReactComponent as Users } from '../../assets/icons/Users.svg';

export const NavigationLinkIcons = {
    dashboard: 'la la-tachometer-alt',
    leads: 'la la-user-check',
    inbox: 'la la-comments',
    funnels: 'la la-images',
    tasks: 'la la-clipboard-check',
    logout: 'la la-sign-out',
    settings: 'la la-bars',
    subscribers: <Users />,
    notifications: 'la la-volume-up',
    speaker: 'la la-volume-up',
    engagements: 'la la-chart-bar',
    leadCheck: 'la la-user-shield',
    newLead: 'la la-user-plus',
};

export type NavigationLinkProps = {
    to: string;
    area?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    withText?: boolean;
    title: string;
    icon: string | ReactNode;
    newSign?: boolean;
    isAdminLink?: boolean;
};

const NavigationLink = (props: NavigationLinkProps) => {
    const location = useLocation();

    const toUrl = props.isAdminLink ? `/admin/${props.to}` : props.to;

    const isActive =
        props.to !== '#' && location.pathname.startsWith(toUrl || '');

    return (
        <Link
            to={toUrl}
            onClick={props.onClick}
            className={`navigationLink ${
                isActive && 'navigationLink--active'
            } ${props.withText && 'navigationLink--withText'}`}
            title={props.title}
        >
            <div className='navigationLink__icon'>
                {typeof props.icon === 'string' ? (
                    <i className={props.icon}></i>
                ) : (
                    props.icon
                )}

                {props.newSign && <div className='navigationLink__newSign' />}
            </div>
            <div className='navigationLink__title'>{props.title}</div>
        </Link>
    );
};

export default NavigationLink;
