import { Store, iNotification } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './Notify.scss';

const notify = (
    message: iNotification['message'],
    nType: iNotification['type'],
    timeout: number
) => {
    return Store.addNotification({
        title: '',
        message: message,
        type: nType,
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeInRight'],
        animationOut: ['animated', 'fadeOutRight'],
        dismiss: {
            duration: timeout,
            pauseOnHover: true,
            showIcon: true,
        },
    });
};

export const NotifySuccess = (
    message: iNotification['message'],
    timeout = 5000
) => {
    return notify(message, 'success', timeout);
};

export const NotifyError = (
    message: iNotification['message'],
    timeout = 5000
) => {
    return notify(message, 'danger', timeout);
};

export const NotifyWarning = (
    message: iNotification['message'],
    timeout = 5000
) => {
    return notify(message, 'warning', timeout);
};
