import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Counter from './Counter';

import './SubMenuItem.scss';

export const SubMenuItemIcons = {
    unified: 'la la-envelope-open-text',
    text: 'la la-comment',
    email: 'la la-envelope',
    trash: 'la la-trash-alt',
    edit: 'la la-user-edit',
    alt: 'la la-pencil-alt',
    settings: 'las la-cog',
    tools: 'las la-tools',
    phone: 'la la-mobile-phone',
};

type CollapseItem = {
    to: string;
    title: string;
};

type SubMenuItemProps = {
    to: string;
    area?: string;
    icon: string;
    hint?: string;
    count?: number;
    title: string;
    noPadding?: boolean;
    collapseItems?: CollapseItem[];
};

const SubMenuItem = (props: SubMenuItemProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(true);
    const showCollapsedItems =
        !collapsed && !!props?.collapseItems && !!props?.collapseItems.length;
    const showCollapseIcon =
        !!props?.collapseItems && !!props?.collapseItems.length;

    const isActive =
        props.to !== '#' && location.pathname.startsWith(props.area || '');

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (props?.collapseItems && props?.collapseItems?.length) {
            event.preventDefault();
            event.stopPropagation();
            setCollapsed(!collapsed);

            if (collapsed || !isActive) {
                navigate(props.collapseItems[0].to);
            }

            return;
        }
    };

    useEffect(() => {
        return () => {
            if (
                props?.collapseItems &&
                !!props?.collapseItems?.length &&
                isActive
            ) {
                setCollapsed(true);
            }
        };
    }, [location.pathname]);

    return (
        <>
            <Link
                className={`SubMenuItem ${isActive && 'SubMenuItem--active'} ${
                    props.noPadding && 'SubMenuItem--noPadding'
                }`}
                to={props.to}
                onClick={handleClick}
            >
                <div className='SubMenuItem__icon'>
                    <i className={props.icon}></i>
                </div>
                <div className='SubMenuItem__wrap'>
                    <div className='SubMenuItem__title'>{props.title}</div>
                    <div className='SubMenuItem__text'>{props.hint}</div>
                </div>
                {props.count !== undefined && props.count > 0 && (
                    <Counter count={props.count} />
                )}
                {showCollapseIcon && (
                    <i
                        className={`la la-angle-down SubMenuItem__collapseIcon`}
                    />
                )}
            </Link>
            {showCollapsedItems &&
                props?.collapseItems?.map((item) => (
                    <Link
                        key={item.to}
                        className={`SubMenuItem SubMenuItem--collapsible ${
                            isActive && 'SubMenuItem--active'
                        } ${props.noPadding && 'SubMenuItem--noPadding'}`}
                        to={item.to}
                    >
                        <div className='SubMenuItem__wrap'>
                            <div className='SubMenuItem__title'>
                                {item.title}
                            </div>
                        </div>
                    </Link>
                ))}
        </>
    );
};
export default SubMenuItem;
