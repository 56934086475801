import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../Views/Auth/types';

type UserInitialState = {
    user: User;
};

const initialState: UserInitialState = {
    user: {},
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<User>) => {
            state.user = {
                ...state.user,
                ...action.payload,
            };
        },
    },
});

export const { updateUser } = userSlice.actions;
export default userSlice.reducer;
