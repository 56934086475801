import { useEffect, useRef } from 'react';

export type TimeoutActionParams = {
    actionCallback: () => Promise<void>;
    timeout?: number;
    successMessage?: string;
    disabled?: boolean;
};

export function useTimeoutAction({
    actionCallback,
    timeout = 60000,
    disabled = false,
}: TimeoutActionParams): void {
    const intervalRef = useRef<NodeJS.Timer | undefined>();

    useEffect(() => {
        if (disabled) {
            return;
        }

        intervalRef.current = setInterval(async () => {
            await actionCallback();
        }, timeout);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [actionCallback]);
}
