import { User, UserRoleTypes } from '../Views/Auth/types';
import { useAppSelector } from '../store/hooks';

export function useAuth() {
    const authenticatedUser = useAppSelector((state) => state.user.user);

    const checkUserRole = (role: UserRoleTypes, user?: User) => {
        if (user) {
            return user?.Role?.RoleName === role;
        }

        return authenticatedUser?.Role?.RoleName === role;
    };

    return { checkUserRole };
}
