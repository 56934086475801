import { Navigate, Outlet, RouteProps, useLocation } from 'react-router-dom';
import { UserRoleTypes } from '../Views/Auth/types';
import { useAppSelector } from '../store/hooks';
import { Loader } from '../Views/Common/Loader';
import { isUserLoggednIn } from '../Services/Auth';

type ProtectedRouteProps = RouteProps & {
    authorizedRoles: UserRoleTypes[];
};

const ProtectedRoute = ({ authorizedRoles }: ProtectedRouteProps) => {
    const user = useAppSelector((state) => state.user.user);
    const isUnAuthorizedUser =
        !user?.Role?.RoleName ||
        !authorizedRoles.includes(user?.Role?.RoleName);
    const prevState = useLocation();

    if (!isUserLoggednIn()) {
        return (
            <Navigate
                to={{ pathname: '/login' }}
                state={{ prevState }}
                replace
            />
        );
    }

    if (!user?.id) {
        return <Loader forceVisible={true} />;
    }

    if (isUnAuthorizedUser) {
        return <Navigate to='/' replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
