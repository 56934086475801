import NavigationLogo from '../MainLayout/NavigationLogo';
import NavigationLink, {
    NavigationLinkIcons,
    NavigationLinkProps,
} from '../MainLayout/NavigationLink';

import '../MainLayout/Navigation.scss';
import { NotifySuccess } from '../../Services/Notify';
import { useNavigate } from 'react-router-dom';
import { Logout } from '../../Services/Auth';

type AdminNavigationProps = {
    expanded?: boolean;
};

const AdminNavigation = (props: AdminNavigationProps) => {
    const navigate = useNavigate();

    const logoutHandler: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();
        Logout(true);
        NotifySuccess('Logout successfully');
        navigate('/admin/login');
    };

    const navMenuItems: NavigationLinkProps[] = [
        {
            to: 'subscribers',
            area: '/subscribers',
            title: 'Subscribers',
            icon: NavigationLinkIcons.subscribers,
            isAdminLink: true,
            withText: props.expanded,
        },
        {
            to: 'engagements',
            area: '/engagements',
            title: 'Engagements',
            icon: NavigationLinkIcons.engagements,
            isAdminLink: true,
            withText: props.expanded,
        },
        {
            to: 'announcements',
            area: '/announcements',
            title: 'Announcements',
            icon: NavigationLinkIcons.notifications,
            isAdminLink: true,
            withText: props.expanded,
        },
        {
            to: 'logs',
            area: '/logs',
            title: 'Logs',
            icon: NavigationLinkIcons.settings,
            isAdminLink: true,
            withText: props.expanded,
        },
        {
            to: '#',
            onClick: logoutHandler,
            title: 'Logout',
            icon: NavigationLinkIcons.logout,
            isAdminLink: true,
            withText: props.expanded,
        },
    ];

    return (
        <nav
            className={`navigation  ${
                props.expanded && 'navigation--expanded'
            }`}
        >
            <NavigationLogo expanded={props.expanded} />
            <div className='navigation__list'>
                <ul className='navigation__links'>
                    {navMenuItems.map((navItem) => (
                        <li key={navItem.to}>
                            <NavigationLink {...navItem} />
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
};

export default AdminNavigation;
