import React from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

function getButtonClassNames(props) {
    const classNames = ['btn'];

    if (props.disabled) {
        classNames.push('btn--disabled');
    }

    if (props.secondary) {
        classNames.push('btn--secondary');
    }

    if (props.danger) {
        classNames.push('btn--danger');
    }

    if (props.success) {
        classNames.push('btn--success');
    }

    if (props.size === 'medium') {
        classNames.push('btn--medium');
    }

    if (props.size === 'small') {
        classNames.push('btn--small');
    }

    if (props.className) {
        classNames.push(props.className);
    }

    if (props.ghost) {
        classNames.push('btn--ghost');
    }

    return classNames.join(' ');
}

const Button = (props) => {
    return (
        <button
            className={getButtonClassNames(props)}
            type={props.type ? props.type : 'submit'}
            onClick={props.click}
            disabled={props.disabled}
            style={props.style}
        >
            {props.name}
        </button>
    );
};

export default Button;

export const ButtonLink = (props) => {
    const classNames = props.blue ? 'btn--blue' : 'btn__light';

    return (
        <Link
            className={`${getButtonClassNames(props)} ${classNames}`}
            to={props.link}
            onClick={props?.click}
            target={props?.newTab ? '_blank' : undefined}
        >
            {props.name}
        </Link>
    );
};

export const ButtonLinkExternal = (props) => {
    const classNames = props.blue ? 'btn btn--blue' : 'btn btn__light';

    return (
        <a className={classNames} href={props.link}>
            {props.name}
        </a>
    );
};

export const IconLinkButton = (props) => {
    const classNames = props.blue ? 'btn__icon btn__icon--blue' : ' btn__icon';

    return (
        <Link
            onClick={props.click}
            className={`${classNames} ${props?.className || ''}`}
            disabled={props.disable}
            to={props.to}
            title={props.title}
        >
            {!props.iconRight && <i className={props.icon}></i>}
            {props.name && <span className='btn__name'>{props.name}</span>}
            {props.iconRight && <i className={props.icon}></i>}
        </Link>
    );
};

export const IconButton = (props) => {
    return (
        <button
            onClick={props.click}
            className={`btn__icon ${
                props.lightBlue ? 'btn__icon--lightBlue' : ''
            } ${props.showOnHover ? 'btn__icon--showOnHover' : ''} ${
                props.blue ? 'btn__icon--blue' : ''
            } ${props.hiddenBg ? 'btn__icon--hiddenBg' : ''} ${
                props?.className || ''
            }`}
            disabled={props.disable}
            title={props.title}
            style={props.style}
            type={props.type || 'button'}
        >
            {props.icon && (
                <i className={props.icon} style={props?.iconStyle}></i>
            )}{' '}
            {props.name}
        </button>
    );
};
