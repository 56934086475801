import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { isUserLoggednIn } from '../Services/Auth';
import SubscriberNavigation from './MainLayout/SubscriberNavigation';
import TelemarketerNavigation from './MainLayout/TelemarketerNavigation';
import './MainLayout.scss';
import { IconButton } from '../Views/Common/Button';
import Search from './MainLayout/Search';
import PlusMenu from './MainLayout/PlusMenu';
import SubNavigationPanel from './MainLayout/SubNavigationPanel';
import SubNavigationPanelMobile from './MainLayout/SubNavigationPanelMobile';
import { GetCounts } from '../Services/ApiServices';
import { NotifyError } from '../Services/Notify';
import { updateAllCounts } from '../store/slices/counts/countsSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { LayoutProps } from './types';
import { useAuth, useTimeoutAction } from '../hooks';
import { UserRoleTypes } from '../Views/Auth/types';
import TermsAndPolicy from '../Views/Common/TermsAndPolicy';
import HelpDeskLink from '../Views/Common/HelpDeskLink';

function MainLayout({
    showSearch = false,
    bgContentTransparent = false,
    showGreeting = false,
    ...props
}: LayoutProps) {
    const dispatch = useAppDispatch();
    const [expandedNavigation, setExpandedNavigation] = useState(false);
    const user = useAppSelector((state) => state.user.user);
    const { checkUserRole } = useAuth();

    const rolesLayoutMap: Record<UserRoleTypes, JSX.Element> = {
        subscriber: <SubscriberNavigation expanded={expandedNavigation} />,
        telemarketer: <TelemarketerNavigation expanded={expandedNavigation} />,
    };

    const toggleNavigation = () => {
        setExpandedNavigation(!expandedNavigation);
    };

    const getCounts = async () => {
        if (!checkUserRole('subscriber')) {
            return;
        }

        try {
            dispatch(updateAllCounts(await GetCounts()));
        } catch (e) {
            if (e instanceof Error) {
                NotifyError(e.message);
            }
        }
    };

    useTimeoutAction({
        actionCallback: getCounts,
        timeout: 60000,
        disabled: !checkUserRole('subscriber'),
    });

    useEffect(() => {
        getCounts();
    }, [user]);

    return (
        <>
            {user.Role?.RoleName && rolesLayoutMap[user.Role?.RoleName]}

            {props.subNavigation && !expandedNavigation && (
                <SubNavigationPanel
                    onToggle={toggleNavigation}
                    navigation={props.subNavigation}
                />
            )}

            <div
                className={`mainLayout__center ${
                    expandedNavigation &&
                    'mainLayout__center--expandedNavigation'
                } ${
                    props.subNavigation &&
                    !expandedNavigation &&
                    'mainLayout__center--withSubNavigation'
                }`}
            >
                <div className='mainLayout__topHeader'>
                    <div
                        className={`mainLayout__expandButton ${
                            props.subNavigation &&
                            ' mainLayout__expandButton--withSubNavigation'
                        }`}
                    >
                        <IconButton
                            click={toggleNavigation}
                            icon='la la-bars'
                        />
                    </div>
                    <div className='mainLayout__title'>{props.title}</div>
                    {showGreeting && (
                        <p className='mainLayout__greeting'>
                            Welcome,&nbsp;{user.name}&#33;
                        </p>
                    )}
                    {showSearch && <Search />}
                    <HelpDeskLink />
                    <PlusMenu />
                </div>

                {props.subNavigation && props.mobileSubMenuHeader && (
                    <SubNavigationPanelMobile
                        navigation={props.subNavigation}
                        header={props.mobileSubMenuHeader}
                    />
                )}

                <div className='mainLayout__contentWrapper'>
                    <div
                        className='mainLayout__content'
                        style={{
                            backgroundColor: bgContentTransparent
                                ? 'transparent'
                                : '',
                        }}
                    >
                        {isUserLoggednIn() ? (
                            props.children
                        ) : (
                            <Navigate to='/' replace />
                        )}
                    </div>
                    <div className='mainLayout__termsPolicy'>
                        <TermsAndPolicy />
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainLayout;
