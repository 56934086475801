import axios, { AxiosError } from 'axios';
import { Config } from '../Config';
import { GetAuthToken, Logout } from './Auth';
import { NotifyError } from './Notify';
import {
    CountsType,
    GenerateParams,
    RequestConfig,
    RequestDataConfig,
    RequestFileConfig,
} from './type';

const BaseUrl = Config.apiBaseURL;

const GetAuthorizationHeader = (isAdmin = false) => {
    const token = GetAuthToken(isAdmin);
    return token ? 'Bearer ' + token : '';
};

const request: RequestConfig = async ({
    url,
    method,
    data,
    headers,
    responseType = 'json',
    onUploadProgress,
    signal,
    isAdmin = false,
}) => {
    try {
        return await axios({
            baseURL: BaseUrl,
            url: url,
            method: method,
            data,
            responseType: responseType,
            headers: {
                'Content-Type': 'application/json',
                Authorization: GetAuthorizationHeader(isAdmin),
                ...headers,
            },
            onUploadProgress,
            signal,
        });
    } catch (e) {
        if (axios.isCancel(e)) {
            return undefined;
        }

        if (e instanceof AxiosError) {
            if (
                e.response?.status === 401 &&
                window.location.pathname.includes('admin')
            ) {
                NotifyError('Your user session expired. Please re-login');
                Logout(true);
                window.location.href = '/admin/login';
            } else if (
                e.response?.status === 401 &&
                window.location.pathname !== '/login'
            ) {
                NotifyError('Your user session expired. Please re-login');
                Logout();
                window.location.href = '/';
            } else {
                throw e;
            }
        } else {
            throw e;
        }
    }
};

export const GetApi = (url?: string, takeLatest?: boolean, isAdmin = false) => {
    takeLatest && AbortRequestController.abortPendingRequest();

    return request({
        url,
        method: 'get',
        signal: takeLatest
            ? AbortRequestController.getSignalToken()
            : undefined,
        isAdmin,
    });
};

export const PostApi: RequestDataConfig = (
    url,
    data,
    headers = { 'Content-Type': 'application/json' },
    isAdmin = false
) => {
    return request({ url, method: 'post', data: data, headers, isAdmin });
};

export const PatchApi: RequestDataConfig = (
    url,
    data,
    headers = { 'Content-Type': 'application/json' },
    isAdmin = false
) => {
    return request({ url, method: 'patch', data, headers, isAdmin });
};

export const DeleteApi = (
    url: string,
    headers = { 'Content-Type': 'application/json' },
    isAdmin = false
) => {
    return request({ url, method: 'delete', headers, isAdmin });
};

export const FileUploadApi: RequestFileConfig = (
    url,
    file,
    onProgress,
    abortSignal,
    serverPath = '',
    multiple = false
) => {
    const postData = new FormData();

    if (multiple && Array.isArray(file)) {
        file.forEach((x) => {
            postData.append('file[]', x as Blob);
        });
    } else {
        postData.append('file', file as Blob);
    }

    if (serverPath) {
        postData.append('path', serverPath);
    }

    return request({
        url,
        method: 'post',
        data: postData,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            type: 'formData',
        },
        responseType: 'json',
        onUploadProgress: onProgress,
        signal: abortSignal,
    });
};

export const GetFile = (url: string) => {
    return request({ url, method: 'get', responseType: 'blob' });
};

export const GetCounts: () => Promise<CountsType> = async () => {
    const response = await GetApi('get-counts');
    if (!response || !response.data || !response.data.data) {
        throw new Error('Cannot get counts');
    }

    return response.data.data;
};

export const generateParams = ({
    url,
    page,
    countPerPage,
    orderBy,
    sorting,
    slug,
    filters,
    id,
    totalPages,
    hasMore,
    starting_after,
    startDate,
    endDate,
}: GenerateParams) => {
    const params = [];
    let newUrl = url;

    if (page) {
        params.push(`page=${encodeURIComponent(page)}`);
    }

    if (countPerPage) {
        params.push(`per_page=${encodeURIComponent(countPerPage)}`);
    }

    if (orderBy) {
        params.push(`orderby=${encodeURIComponent(orderBy)}`);
    }

    if (sorting) {
        params.push(`sort=${encodeURIComponent(sorting)}`);
    }

    if (filters) {
        Object.entries(filters).forEach(
            (filterEntry) =>
                filterEntry[1] &&
                params.push(
                    `${filterEntry[0]}=${encodeURIComponent(filterEntry[1])}`
                )
        );
    }

    if (slug) {
        params.push(`slug=${encodeURIComponent(slug)}`);
    }

    if (id) {
        params.push(`id=${encodeURIComponent(id)}`);
    }

    if (totalPages) {
        params.push(`totalPages=${encodeURIComponent(totalPages)}`);
    }

    if (hasMore !== undefined) {
        params.push(`has_more=${encodeURIComponent(hasMore)}`);
    }

    if (starting_after) {
        params.push(`starting_after=${encodeURIComponent(starting_after)}`);
    }

    if (startDate) {
        params.push(`startDate=${encodeURIComponent(startDate)}`);
    }

    if (endDate) {
        params.push(`endDate=${encodeURIComponent(endDate)}`);
    }

    if (params.length > 0) {
        newUrl += `?${params.join('&')}`;
    }

    return newUrl;
};

export const AbortRequestController = (() => {
    let controller = new AbortController();
    let signal = controller.signal;

    const abortPendingRequest = () => {
        controller.abort();
        controller = new AbortController();
        signal = controller.signal;
    };

    const getSignalToken = () => signal;

    return {
        abortPendingRequest,
        getSignalToken,
    };
})();
