import { Link } from 'react-router-dom';

import './NavigationUserLink.scss';
import { useAppSelector } from '../../store/hooks';

import avatarSample from '../../assets/img/avatar-sample.png';

const NavigationUserLink = (props: { withText?: boolean }) => {
    const user = useAppSelector((state) => state.user.user);

    return (
        <Link
            className={`navigationUserLink ${
                props.withText && 'navigationUserLink--withText'
            }`}
            to='/settings/account'
            title={user.name}
        >
            <div className='navigationUserLink__wrapper'>
                <img
                    className='navigationUserLink__pic'
                    src={user.ProfilePic ? user.ProfilePic : avatarSample}
                    alt='Settings'
                />
            </div>
            <div className='navigationUserLink__title'>{user.name}</div>
        </Link>
    );
};

export default NavigationUserLink;
