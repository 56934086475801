import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InboxInitialValue = {
    emailsCounts: number;
    textCounts: number;
    trashedCount: number;
};

const initialState: InboxInitialValue = {
    emailsCounts: 0,
    textCounts: 0,
    trashedCount: 0,
};

const inboxSlice = createSlice({
    name: 'inbox',
    initialState,
    reducers: {
        updateInboxCounters: (
            state,
            action: PayloadAction<InboxInitialValue>
        ) => {
            return action.payload;
        },
    },
});

export const { updateInboxCounters } = inboxSlice.actions;
export default inboxSlice.reducer;
