import { Config } from '../Config';
import { GetApi, PostApi } from './ApiServices';

declare var window: any;

export const FACEBOOK_INTEGRATION_PERMISSIONS = [
    'email',
    'pages_show_list',
    'business_management',
    'pages_manage_metadata',
    'leads_retrieval',
    'pages_manage_ads',
];

class Facebook {
    Init = () => {
        return new Promise<void>((resolve) => {
            // wait for facebook sdk to initialize before starting the react app
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: Config.facebookAppId,
                    cookie: true,
                    xfbml: true,
                    version: 'v18.0',
                });

                resolve();
            };

            // load facebook sdk script
            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s) as HTMLScriptElement;
                js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js';
                fjs?.parentNode?.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        });
    };

    GetExistingAccessToken = () => {
        const existingAuth = window.FB.getAuthResponse();
        if (existingAuth && existingAuth.accessToken) {
            return existingAuth.accessToken;
        } else {
            return null;
        }
    };

    GetLoginStatus = async () => {
        const result = await new Promise<{
            authResponse: {
                userID: string;
                expiresIn: number;
                accessToken: string;
                signedRequest: string;
                graphDomain: 'facebook';
                data_access_expiration_time: number;
            } | null;
            status: 'unknown' | 'connected';
        }>((resolve) => {
            window.FB.getLoginStatus(resolve);
        });

        return result;
    };

    HasPermissions = async (permissions: string[]) => {
        const loginStatus: any = await this.GetLoginStatus();

        if (loginStatus.status === 'connected') {
            const userId = loginStatus.authResponse.userID;
            const facebookPermissionList: any = await this.Api(
                `${userId}/permissions`,
                'get',
                null
            );

            const facebookPermissionSet = new Set();
            facebookPermissionList.data
                .filter((x: any) => x.status === 'granted')
                .forEach((e: any) => {
                    facebookPermissionSet.add(e.permission);
                });

            return permissions.every((x) => facebookPermissionSet.has(x));
        }
    };

    Login = async (scopes: string | string[]) => {
        const { authResponse }: any = await new Promise((resolve) => {
            window.FB.login(resolve, {
                scope: scopes,
                config_id: Config.facebookConfigId,
            });
        });
        if (authResponse) {
            return authResponse.accessToken;
        } else {
            return null;
        }
    };

    Api = async (url: string, method: string, data: any) => {
        const result = await new Promise((resolve) => {
            window.FB.api(url, method, data, (responce: any) => {
                resolve(responce);
            });
        });

        return result;
    };

    GetInfo = async () => {
        return this.Api('/me?fields=id,access_token', 'get', {
            fields: 'name,email,picture',
        });
    };

    GetForms = async () => {
        const result: any = {};

        const pagesResponce: any = await this.Api(
            '/me/accounts?fields=id,access_token',
            'get',
            { fields: 'id,name,access_token,business' }
        );

        if (pagesResponce.data) {
            for (const page of pagesResponce.data) {
                const formResponce: any = await this.Api(
                    `/${page.id}/leadgen_forms`,
                    'get',
                    { access_token: page.access_token }
                );
                if (formResponce.data) {
                    result[page.id] = page;

                    result[page.id].forms = formResponce.data.map(
                        (form: any) => {
                            return {
                                id: form.id,
                                name: form.name,
                            };
                        }
                    );
                }
            }
        }

        return result;
    };

    SubscribeOnPage = async (pageId: number, accessToken: string) => {
        const responce: any = await this.Api(
            `/${pageId}/subscribed_apps`,
            'post',
            {
                access_token: accessToken,
                subscribed_fields: 'leadgen',
            }
        );
        if (responce.error) {
            throw new Error(responce.error.message);
        }
    };

    RemovePermissions = async () => {
        let loginStatus: any = await this.GetLoginStatus();

        if (loginStatus.status !== 'connected') {
            await this.Login(FACEBOOK_INTEGRATION_PERMISSIONS);
            loginStatus = await this.GetLoginStatus();
        }

        if (loginStatus.status === 'connected') {
            const userId = loginStatus.authResponse.userID;
            const batch = FACEBOOK_INTEGRATION_PERMISSIONS.map((x) => {
                return {
                    method: 'DELETE',
                    relative_url: `${userId}/permissions/${x}`,
                };
            });

            const responce: any = await this.Api(`/`, 'post', {
                batch: batch,
            });
            if (responce.error) {
                throw new Error(responce.error.message);
            }
        } else {
            throw new Error('Cannot coonect to Facebook');
        }
    };

    ConnectIntegration = async () => {
        const token = await this.Login(FACEBOOK_INTEGRATION_PERMISSIONS);

        if (token === null) {
            throw new Error('Cannot get Facebook authorization token');
        }

        const response = await PostApi('facebook/connect');
        let res = response?.data;

        if (res.status === 200) {
            return true;
        } else {
            throw new Error(res.message);
        }
    };

    DisconnectIntegration = async () => {
        await this.RemovePermissions();

        const response = await PostApi('facebook/disconnect');
        let res = response?.data;

        if (res.status === 200) {
            return true;
        } else {
            throw new Error(res.message);
        }
    };

    GetIntegrationStatus = async () => {
        const response = await GetApi('facebook/status');
        let res = response?.data;

        if (res.status === 200) {
            return res.data.connected;
        } else {
            throw new Error(res.message);
        }
    };

    RefreshCampaignTokens = async ({
        shortLivedAccessToken,
    }: {
        shortLivedAccessToken: string;
    }) => {
        const response = await PostApi('facebook/refresh-tokens', {
            shortLivedAccessToken,
        });
        let res = response?.data;

        if (res.status === 200) {
            return res;
        } else {
            throw new Error(res.message);
        }
    };
}

const facebook = new Facebook();

export { facebook };
