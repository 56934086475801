import { useNavigate } from 'react-router-dom';
import { Logout } from '../../Services/Auth';

import './Navigation.scss';
import NavigationLogo from './NavigationLogo';
import NavigationLink, { NavigationLinkIcons } from './NavigationLink';
import NavigationUserLink from './NavigationUserLink';
import { NotifySuccess } from '../../Services/Notify';
import { useAppSelector } from '../../store/hooks';

export type NavigationProps = {
    expanded?: boolean;
};

const SubscriberNavigation = (props: NavigationProps) => {
    const navigate = useNavigate();
    const inboxCount = useAppSelector((state) => state.counts.inboxTotal) || 0;
    const unreadAnnouncementsCount =
        useAppSelector((state) => state.counts.unreadAnnouncements) || 0;

    const logoutHandler: React.MouseEventHandler<HTMLAnchorElement> = async (
        e
    ) => {
        e.preventDefault();
        await Logout();
        NotifySuccess('Logout successfully');
        navigate('/login');
    };

    return (
        <nav
            className={`navigation ${props.expanded && 'navigation--expanded'}`}
        >
            <NavigationLogo expanded={props.expanded} />

            <div className='navigation__list'>
                <ul className='navigation__links'>
                    <li>
                        <NavigationLink
                            to='/dashboard'
                            area='/dashboard'
                            title='Dashboard'
                            icon={NavigationLinkIcons.dashboard}
                            withText={props.expanded}
                        />
                    </li>

                    <li>
                        <NavigationLink
                            to='/leads'
                            area='/leads'
                            title='Leads'
                            icon={NavigationLinkIcons.leads}
                            withText={props.expanded}
                        />
                    </li>

                    <li>
                        <NavigationLink
                            to='/inbox/unified/chat'
                            area='/inbox'
                            title='Inbox'
                            icon={NavigationLinkIcons.inbox}
                            withText={props.expanded}
                            newSign={inboxCount > 0}
                        />
                    </li>

                    <li>
                        <NavigationLink
                            to='/campaigns'
                            area='/campaigns'
                            title='Campaigns'
                            icon={NavigationLinkIcons.funnels}
                            withText={props.expanded}
                        />
                    </li>

                    <li>
                        <NavigationLink
                            to='/tasks'
                            area='/tasks'
                            title='Tasks'
                            icon={NavigationLinkIcons.tasks}
                            withText={props.expanded}
                        />
                    </li>

                    <li>
                        <NavigationLink
                            to='/announcements'
                            area='/announcements'
                            title='Announcements'
                            icon={NavigationLinkIcons.speaker}
                            withText={props.expanded}
                            newSign={unreadAnnouncementsCount > 0}
                        />
                    </li>

                    <li>
                        <NavigationLink
                            to='#'
                            onClick={logoutHandler}
                            title='Logout'
                            icon={NavigationLinkIcons.logout}
                            withText={props.expanded}
                        />
                    </li>
                    <li className='navigation__settingsButton'>
                        <NavigationLink
                            to='/settings/account'
                            area='/settings'
                            title='Settings'
                            icon={NavigationLinkIcons.settings}
                            withText={props.expanded}
                        />
                    </li>
                </ul>
                <div className='navigation__user'>
                    <NavigationUserLink withText={props.expanded} />
                </div>
            </div>
        </nav>
    );
};

export default SubscriberNavigation;
