import './Backdrop.scss';

type BackdropProps = { onClick?: () => void; visible?: boolean };

const Backdrop = (props: BackdropProps) => {
    return (
        <>
            {props.visible && (
                <div onClick={props.onClick} className='backdrop' />
            )}
        </>
    );
};

export default Backdrop;
