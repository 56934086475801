import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LoadInitialState = {
    status: boolean;
    loadStack: number;
};

const initialState: LoadInitialState = {
    status: false,
    loadStack: 0,
};

const loadSlice = createSlice({
    name: 'load',
    initialState,
    reducers: {
        updateLoad: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.loadStack = state.loadStack + 1;
                state.status = action.payload;
            } else {
                state.loadStack = state.loadStack - 1;
                if (state.loadStack <= 0) {
                    state.status = action.payload;
                }
            }
        },
    },
});

export const { updateLoad } = loadSlice.actions;
export default loadSlice.reducer;
