import { useState } from 'react';

export function useToggle(initialState = false) {
    const [toggle, setUseToggle] = useState(initialState);

    const handleToggle = () => {
        setUseToggle((prevState) => !prevState);
    };

    return { toggle, handleToggle };
}
