import { LayoutProps } from '../types';
import AdminNavigation from './AdminNavigation';

import '../MainLayout.scss';
import { useToggle } from '../../hooks';
import { IconButton } from '../../Views/Common/Button';

type AdminLayoutProps = LayoutProps;

const AdminLayout = ({
    bgContentTransparent = false,
    ...props
}: AdminLayoutProps) => {
    const { toggle, handleToggle } = useToggle(true);

    return (
        <>
            <AdminNavigation expanded={toggle} />
            <div
                className={`mainLayout__center ${
                    toggle && 'mainLayout__center--expandedNavigation'
                } `}
            >
                <div className='mainLayout__topHeader'>
                    <div className='mainLayout__expandButton'>
                        <IconButton click={handleToggle} icon='la la-bars' />
                    </div>
                    <div className='mainLayout__title'>{props.title}</div>
                </div>
                <div className='mainLayout__contentWrapper'>
                    <div
                        className='mainLayout__content'
                        style={{
                            backgroundColor: bgContentTransparent
                                ? 'transparent'
                                : '',
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminLayout;
