import { DependencyList, useEffect, useRef, useState } from 'react';

type ReadMoreParams = {
    linesLimit?: number;
    dependencies?: DependencyList;
};

export function useReadMore<T>({
    linesLimit = 6,
    dependencies = [],
}: ReadMoreParams) {
    const messageBodyRef = useRef<T>(null);
    const [showReadMore, setShowReadMore] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const calculateBodyLines = () => {
        if (!messageBodyRef?.current) {
            return;
        }

        if (!(messageBodyRef?.current instanceof HTMLElement)) {
            return;
        }

        const messageBodyHeight = messageBodyRef?.current?.offsetHeight;

        const messageBodyLineHeight = parseInt(
            messageBodyRef?.current?.style?.lineHeight || '24'
        );

        const bodyLines = Math.round(
            (messageBodyHeight || 0) / messageBodyLineHeight
        );

        setShowReadMore(bodyLines > linesLimit);
    };

    useEffect(() => {
        calculateBodyLines();
    }, [messageBodyRef?.current, ...dependencies]);

    return { showReadMore, ref: messageBodyRef, handleReadMore, isExpanded };
}
