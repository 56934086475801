import { useSearchParams } from 'react-router-dom';

export function useAppSearchParams() {
    const [searchParams, setSearchParams] = useSearchParams();

    const getSearchParamsObject = () => {
        let params: Record<string, string> = {};

        for (let param of searchParams) {
            params[param[0]] = param[1];
        }
        return params;
    };

    return { searchParams, setSearchParams, getSearchParamsObject };
}
