import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isAdminLoggedIn } from '../../Services/Auth';

const AdminProtectedRoutes = () => {
    const prevState = useLocation();

    if (!isAdminLoggedIn()) {
        return (
            <Navigate
                to={{ pathname: '/admin/login' }}
                state={{ prevState }}
                replace
            />
        );
    }

    return <Outlet />;
};

export default AdminProtectedRoutes;
