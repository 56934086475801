import React, { useEffect, useState } from 'react';

import './Search.scss';
import { IconButton } from '../../Views/Common/Button';
import Backdrop from '../../Views/Common/Backdrop';
import { useAppDispatch } from '../../store/hooks';
import { updateSearch } from '../../store/slices/search/searchSlice';
import { useLocation } from 'react-router-dom';

const Search = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const [expanded, setExpanded] = useState(false);
    const [key, setKey] = useState('');

    const toggle = () => {
        setExpanded(!expanded);

        expanded && handleSearch();
    };

    const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        setKey(event.target.value);
    };

    const onSearchKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
        event
    ) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        dispatch(updateSearch(key));
        setExpanded(false);
    };

    useEffect(() => {
        return () => {
            setKey('');
            dispatch(updateSearch(''));
        };
    }, [location.pathname]);

    return (
        <div className='search'>
            <IconButton click={toggle} icon='la la-search ' />

            {expanded && (
                <div className='search__dropdown'>
                    <div className='search__field'>
                        <input
                            className='search__input'
                            type='search'
                            id='search-text'
                            placeholder='Start typing…'
                            name='search'
                            value={key}
                            onChange={onSearchChange}
                            onKeyDown={onSearchKeyDown}
                            autoFocus
                        />
                        <div className='search__icon'>
                            <i
                                className='la la-search'
                                onClick={handleSearch}
                            ></i>
                        </div>
                    </div>
                </div>
            )}
            <Backdrop onClick={toggle} visible={expanded} />
        </div>
    );
};

export default Search;
