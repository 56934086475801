import './PlusMenuLink.scss';
import { Link } from 'react-router-dom';

export const PlusMenuLinkIcons = {
    lead: 'la la-tachometer-alt',
    email: 'la la-envelope',
    sms: 'la la-sms',
    funnel: 'la la-images',
    task: 'la la-clipboard-check',
};

type PlusMenuLinkProps = {
    to: string;
    icon: string;
    title: string;
};

const PlusMenuLink = (props: PlusMenuLinkProps) => {
    return (
        <Link className='plusMenuLink' to={props.to}>
            <div className='plusMenuLink__icon'>
                <i className={props.icon}></i>
            </div>
            <div className='plusMenuLink__title'>{props.title}</div>
        </Link>
    );
};

export default PlusMenuLink;
