import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SearchInitialState = {
    key: string;
};

const initialState: SearchInitialState = {
    key: '',
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        updateSearch: (state, action: PayloadAction<string>) => {
            state.key = action.payload;
        },
    },
});

export const { updateSearch } = searchSlice.actions;
export default searchSlice.reducer;
