import { configureStore } from '@reduxjs/toolkit';

import UserReducer from './slices/user/userSlice';
import CountsReducer from './slices/counts/countsSlice';
import LoadReducer from './slices/load/loadSlice';
import SearchReducer from './slices/search/searchSlice';
import InboxCounts from './slices/inboxCounts/inboxSlice';

const store = configureStore({
    reducer: {
        user: UserReducer,
        counts: CountsReducer,
        load: LoadReducer,
        search: SearchReducer,
        inboxCounts: InboxCounts,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
