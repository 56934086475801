import { useState } from 'react';
import { IconButton } from '../../Views/Common/Button';
import Backdrop from '../../Views/Common/Backdrop';

import './PlusMenu.scss';
import PlusMenuLink, { PlusMenuLinkIcons } from './PlusMenuLink';

const PlusMenu = () => {
    const [expanded, setExpanded] = useState(false);

    const toggle = () => {
        setExpanded(!expanded);
    };

    return (
        <div className='plusMenu'>
            <IconButton click={toggle} icon='la la-plus-circle' />

            {expanded && (
                <div className='plusMenu__dropdown' onClick={toggle}>
                    <PlusMenuLink
                        to='/leads/new'
                        icon={PlusMenuLinkIcons.lead}
                        title='New Lead'
                    />

                    <PlusMenuLink
                        to='/inbox/email/new'
                        icon={PlusMenuLinkIcons.email}
                        title='New E-mail'
                    />
                    <PlusMenuLink
                        to='/inbox/text/new'
                        icon={PlusMenuLinkIcons.sms}
                        title='New SMS'
                    />
                    <PlusMenuLink
                        to='/campaigns/new'
                        icon={PlusMenuLinkIcons.funnel}
                        title='New Campaign'
                    />
                    <PlusMenuLink
                        to='/tasks/new'
                        icon={PlusMenuLinkIcons.task}
                        title='New Task'
                    />
                </div>
            )}
            <Backdrop onClick={toggle} visible={expanded} />
        </div>
    );
};

export default PlusMenu;
