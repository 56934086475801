import logoFull from '../../assets/img/leadqualifier-logo-no-icon.svg';
import { IconButton } from '../../Views/Common/Button';

import './SubNavigationPanel.scss';
import { useAppSelector } from '../../store/hooks';
import { ReactNode } from 'react';

import avatarSample from '../../assets/img/avatar-sample.png';

type SubNavigationPanelProps = {
    onToggle?: () => void;
    navigation?: ReactNode;
};

const SubNavigationPanel = (props: SubNavigationPanelProps) => {
    const user = useAppSelector((state) => state.user.user);

    const userCompanyLogo = user.CompanyLogo ? user.CompanyLogo : logoFull;

    return (
        <div className='subNavigation'>
            <div className='subNavigation__head'>
                <div className='subNavigation__preview'>
                    <img
                        className='subNavigation__userIcon'
                        src={user.ProfilePic ? user.ProfilePic : avatarSample}
                        alt={user.PublicName}
                    />
                </div>
                <div className='subNavigation__wrap'>
                    <img
                        className='subNavigation__companyLogo'
                        src={userCompanyLogo}
                        alt={user.PublicName}
                    />
                </div>
                <div className='subNavigation__collapseButton'>
                    <IconButton
                        icon='la la-angle-left'
                        click={props.onToggle}
                    />
                </div>
            </div>

            <div className='subNavigation__inner'>{props.navigation}</div>
        </div>
    );
};
export default SubNavigationPanel;
